<template>
  <div class="stepper">
    <div class="wrap">
      <div class="item" :class="{ OK: step == 1 && status, pass: step > 1 }">
        <div class="external"></div>
        <div class="inner"></div>
        <div class="text">確認資訊</div>
      </div>
      <div class="line"></div>
      <div class="item" :class="{ OK: step == 2 && status, pass: step > 2 }">
        <div class="external"></div>
        <div class="inner"></div>
        <div class="text">付款結帳</div>
      </div>
      <div class="line"></div>
      <div class="item" :class="{ OK: step == 3 && status, pass: step > 3 }">
        <div class="external"></div>
        <div class="inner"></div>
        <div class="text">購買完成</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: Number,
    status: {
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_mixin.scss";
.stepper {
  width: 100%;
  @include pad {
    margin: 24px 0 16px;
  }
}
.wrap {
  width: 100%;
  margin: 0 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include pad {
    width: 210px;
    margin: 0 auto;
  }
  .item {
    width: 20px;
    height: 20px;
    position: relative;
    opacity: 0.7;
    @include pad {
      width: 18px;
      height: 18px;
    }
    &.OK {
      opacity: 1;
      .inner {
        background-color: #6bc66f !important;
        box-shadow: 0 0 8px 0 #6bc66f !important;
      }
      .text {
        color: $secondary-color-1;
      }
    }
    &.pass {
      opacity: 1;
      .inner {
        background-color: #9b9b9b !important;
      }
      .text {
        color: #9b9b9b !important;
      }
    }
    .external {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #f0f2f4;
      box-shadow: -2px -2px 3px 0 #fff, -2px -2px 1px 0 #fff,
        2px 2px 1px 0 rgba(0, 0, 0, 0.3);
      filter: blur(1.5px);
    }
    .inner {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d9d9d9;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include pad {
        width: 8px;
        height: 8px;
      }
    }
    .text {
      position: absolute;
      bottom: -43px;
      left: 10px;
      white-space: nowrap;
      transform: translateX(-50%);
      font-size: 20px;
      line-height: 30px;
      color: #9b9b9b;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  .line {
    width: 64px;
    height: 2px;
    background-color: #d9d9d9;
    @include pad {
      width: 48px;
    }
  }
}
</style>
