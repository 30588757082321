var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stepper" }, [
    _c("div", { staticClass: "wrap" }, [
      _c(
        "div",
        {
          staticClass: "item",
          class: { OK: _vm.step == 1 && _vm.status, pass: _vm.step > 1 }
        },
        [
          _c("div", { staticClass: "external" }),
          _c("div", { staticClass: "inner" }),
          _c("div", { staticClass: "text" }, [_vm._v("確認資訊")])
        ]
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        {
          staticClass: "item",
          class: { OK: _vm.step == 2 && _vm.status, pass: _vm.step > 2 }
        },
        [
          _c("div", { staticClass: "external" }),
          _c("div", { staticClass: "inner" }),
          _c("div", { staticClass: "text" }, [_vm._v("付款結帳")])
        ]
      ),
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        {
          staticClass: "item",
          class: { OK: _vm.step == 3 && _vm.status, pass: _vm.step > 3 }
        },
        [
          _c("div", { staticClass: "external" }),
          _c("div", { staticClass: "inner" }),
          _c("div", { staticClass: "text" }, [_vm._v("購買完成")])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }